<template>
  <div>
    <navbar />
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import Navbar from '../site-content/Navbar.vue'
import AppFooter from '../site-content/AppFooter.vue'

export default {
  components: {
    Navbar,
    AppFooter,
  },
}
</script>

<style>

</style>
